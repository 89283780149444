<template>
  <div class="dflexc-jcs" id="num-pad-panel">
    <NumPadDisplay :display_val="display"/>
    <NumPadBtnPanel @btn-pressed="(btn) => update_display(btn)"/>
  </div>
</template>

<style scoped lang="scss">
  @import '../../assets/styles/colors';
  #num-pad-panel {
    border: 3px outset $fg-base;
    min-height: 300px;

    #tmp_element {
      position: absolute;
      left: -10000px;
    }
  }
</style>

<script setup>
import {onMounted, reactive, ref} from "vue";

import NumPadDisplay from '../numpad/num_pad_display.vue'
import NumPadBtnPanel from '../numpad/num_pad_btn_panel.vue'

const display = ref('0')
const props = defineProps(['store'])
const emit = defineEmits(['genResults', 'generate'])
// store will be either jewels or debris
const genStore = props.store
let tmpEle = document.getElementById('dice_results_view')

onMounted(() => {

})

function update_display(val) {

  if (val === 'Clr') {
    display.value = '0'
  } else if (val === 'Del') {
    if (display.value === '0')
      return
    display.value = display.value.substring(0, display.value.length - 1)
    if (display.value === '')
      display.value = '0'
  } else if (val === 'Roll') {
    if(display.value === '0')
      return
    emit('generate', display.value)
  } else if(val === ' ') {
    // do nothing yet
  } else {
    if(display.value === '0')
      display.value = val
    else
      display.value = display.value + val
  }

}

function generate() {
  genStore.roll(parseInt(display.value))
  emit('genResults')
  // jewelServ.roll(parseInt(display.value))
  //     .then((res) => {
  //       Object.assign(jewel_pkg, res)
  //
  //       const jewel_results = {
  //         list: [],
  //         qty: 0,
  //         total_val: 0,
  //         maxValWidth: 0,
  //         maxCntWidth: 0
  //       }
  //
  //       Object.assign(jewel_results, res)
  //       genStore.history.push(jewel_results)
  //       console.log('jewel store:', genStore)
  //
  //       setTimeout(() => {
  //         determineMaxWidth()
  //         emit('JewelRes', jewel_pkg)
  //       }, 1)
  //     })
}

</script>
