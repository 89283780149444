import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/styles/global_classes.scss'
import Unicon from "vue-unicons";
import {
  uniAngleDoubleRight, uniAngleDoubleLeft,
  uniAngleRight, uniAngleLeft, uniEnter
}
  from "vue-unicons/dist/icons";

Unicon.add([uniAngleDoubleRight, uniAngleDoubleLeft,
  uniAngleRight, uniAngleLeft, uniEnter
])

createApp(App).use(Unicon).use(router).mount('#app')
