<template>
  <div class="dflexr-jcs hedge-container">
    <div class="dflexc-jcs app-selection-panel" >
      <ul class="btn-list">
        <li class="btn btn-hedge-app" :class="{'active-app': active_app==='dice'}"
            @click="setActiveApp('dice')">
          Dice
        </li>
        <li class="btn btn-hedge-app" :class="{'active-app': active_app==='jewels'}"
            @click="setActiveApp('jewels')">
          Jewels
        </li>
        <li class="btn btn-hedge-app" :class="{'active-app': active_app==='special_items'}"
            @click="setActiveApp('special_items')">
          Spec. Items
        </li>
      </ul>
      <div class="return-to-empirewargames">
        <router-link class="nav-link" to="EmpireWargames">
          Return to Empire Wargames
        </router-link>
      </div>
    </div>
    <div class="dflexc-jcs hedge-app-panel">
      <dice-roller-view v-if="active_app==='dice'"/>
      <JewelRollerView v-if="active_app==='jewels'"/>
      <SpecialIemsView v-if="active_app==='special_items'"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../assets/styles/hedge-view';



</style>

<script setup>
import DiceRollerView from "@/views/DiceRollerView.vue"
import JewelRollerView from "@/views/JewelRollerView.vue"
import SpecialIemsView from "@/views/SpecialtemRollerView.vue"
import '../assets/styles/hedge-view.scss'
import {ref} from "vue";

const active_app = ref('dice')


function setActiveApp(app) {
  active_app.value = app
}
</script>
