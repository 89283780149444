<template>
  <div class="dflexr-jcc EW-main-container">
    <div class="dflexr-jcsb EW-main-view">
      <div class="dflexc-jcs" id="col1">
        <img src="@/assets/images/EW_AD_images/ad_L1.png" alt="ad_L1.png not found">
        <img src="@/assets/images/EW_AD_images/ad_L2.png" alt="ad_L2.png not found">
        <img src="@/assets/images/EW_AD_images/ad_L3.png" alt="ad_L3.png not found">
        <img src="@/assets/images/EW_AD_images/ad_L4.png" alt="ad_L4.png not found">
      </div>
      <div class="dflexc-jcs" id="col2">
        <img src="@/assets/images/EW_AD_images/ad_C1.png" alt="ad_C1.png not found">
        <div class="dflexr-jcsb" id="bk-row1">
          <router-link to="BookViewIron">
            <img src="@/assets/images/EW_AD_images/ad_C2i.png" alt="ad_C2i.png not found"
                 @mouseenter="showBookCover('bk1', 2)"
                 @mouseleave="hideBookCover('bk1')">
          </router-link>


          <img src="@/assets/images/EW_AD_images/ad_C2w.png" alt="ad_C2w.png not found"
               @mouseenter="showBookCover('bk2', 1)"
               @mouseleave="hideBookCover('bk2')">

          <div id="c2-bk1-hover-image" :class="{behidden: !showBK.bk1}" :style="{opacity: bk_opacity.bk1}" >
            <img src="@/assets/images/EW_AD_images/books/bk1.jpg" alt="Ironhedge book cover">
          </div>
          <div id="c2-bk2-hover-image" :class="{behidden: !showBK.bk2}" :style="{opacity: bk_opacity.bk2}">
            <img src="@/assets/images/EW_AD_images/books/bk2.jpg" alt="Westhedge book cover">
          </div>
        </div>
        <div class="dflexr-jcsb" id="bk-row2">
          <img src="@/assets/images/EW_AD_images/ad_C2g.png" alt="ad_C2g.png not found"
               @mouseenter="showBookCover('bk3', 2)"
               @mouseleave="hideBookCover('bk3')">
          <img src="@/assets/images/EW_AD_images/ad_C2s.png" alt="ad_C2s.png not found"
               @mouseenter="showBookCover('bk4', 1)"
               @mouseleave="hideBookCover('bk4')">

          <div id="c2-bk3-hover-image" :class="{behidden: !showBK.bk3}" :style="{opacity: bk_opacity.bk3}">
            <img src="@/assets/images/EW_AD_images/books/bk3.jpg" alt="Ganghedge book cover">
          </div>
          <div id="c2-bk4-hover-image" :class="{behidden: !showBK.bk4}" :style="{opacity: bk_opacity.bk4}">
            <img src="@/assets/images/EW_AD_images/books/bk4.jpg" alt="Starhedge book cover">
          </div>
        </div>
        <img src="@/assets/images/EW_AD_images/ad_C3.png" alt="ad_C3.png not found">
      </div>
      <div class="dflexc-jcs" id="col3">
        <img src="@/assets/images/EW_AD_images/ad_R1.png" alt="ad_R1.png not found">
        <img src="@/assets/images/EW_AD_images/ad_R2.png" alt="ad_R2.png not found">
        <img src="@/assets/images/EW_AD_images/ad_R3.png" alt="ad_R3.png not found">
        <img src="@/assets/images/EW_AD_images/ad_R4.png" alt="ad_R4.png not found">
        <img src="@/assets/images/EW_AD_images/ad_R5.png" alt="ad_R5.png not found">
      </div>
      <div id="c1-hover-image" class="behidden">
        <img src="@/assets/images/EW_AD_images/IronhedgeGameset.jpg" alt="IronhedgeGameset not found">
        <!--      <%= image_tag image_url('IronhedgeGameset.jpg') %>-->
      </div>


    </div>
    <div class="dflexc-jcs col-right">
      <div class="hedge-assist-link">
        <router-link class="nav-link" to="hedge_assist">
          Hedge Assistant
        </router-link>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
@import "../assets/styles/empirewargames-view";

</style>

<script setup>

import {reactive, ref} from "vue";
import HedgeAssistView from "@/views/HedgeAssistView.vue";

const showBK = reactive({
  bk1: false,
  bk2: false,
  bk3: false,
  bk4: false
})

const bk_opacity = reactive ({
  bk1: 0,
  bk2: 0,
  bk3: 0,
  bk4: 0
})

const bk_timer = {
  bk1: null,
  bk2: null,
  bk3: null,
  bk4: null
}

let timer_id = null
const show_delay = .05
function showBookCover(bk, duration) {
  const book = `c2-${bk}-hover-image`
  showBK[bk] = !showBK[bk]
  let opacity = 0
  const opacity_inc = 1 / (duration / show_delay)
  const elm = document.getElementById(book)
  clearInterval(bk_timer[bk])
  const start_time = new Date().getMilliseconds()

  function frameset() {
    const time_frame = start_time + show_delay
    if(new Date().getMilliseconds() > time_frame) {
      bk_opacity[bk] += opacity_inc
      // elm.style.opacity = bk_opacity[bk]
    }
    if(bk_opacity[bk] >= 1)
      clearInterval(bk_timer[bk])
  }
  bk_timer[bk] = setInterval(frameset, duration * show_delay)
}

function hideBookCover(bk) {
  showBK[bk] = !showBK[bk]
  const book = `c2-${bk}-hover-image`
  const elm = document.getElementById(book)
  bk_opacity[bk] = 0
  // elm.style.opacity = 0
  clearInterval(bk_timer[bk])
}

</script>
