<template>
  <div class="btn" :id="'btn-'+btnLabel" :class="{disabled: isDisabled}"
  @click="$emit('clicked', btnLabel)">
    {{btnLabel}}
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/common-buttons';
</style>

<script setup>
import {onMounted, onUpdated} from "vue";

const props = defineProps(["btnLabel", "styleclass", 'isDisabled'])

onMounted(() => {
  addStyle()
})

onUpdated(() => {
  addStyle()
})

function addStyle() {
  const idstr = 'btn-'+props.btnLabel
  const elm = document.getElementById(idstr)
  elm.classList.add(props.styleclass)
}


</script>
