<template>
  <div class="dflexr-jcs spItem-results-panel results-panel">
    <div class="dflexc-jcs flexwrap-on spItem-col" v-if="ready">
      <spItem v-for="item in siPkg.list" :item="item"
            :key="item.value"
            :maxValWidth="siPkg.maxValWidth" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.spItem-results-panel {
    height: calc(100% - 80px);
}

.spItem-col {
  margin: 0 auto;
  max-height: 100%;
  grid-column-gap: 8px;
}

</style>

<script setup>

import spItem from "@/components/SpecialItems/sp_item.vue";
import {onMounted, onUpdated, ref} from "vue";
const ready = ref(false)

const props = defineProps(['siPkg'])
let siStore = null

onMounted(() => {
  siStore = window.novasectorapp.siStore
  getCurrentSet()
  update_width()
  ready.value = true
})

onUpdated(() => {
  update_width()
})

function update_width() {
  const valWidth = siStore.currentSet.maxValWidth
  const numCols = siStore.currentSet.numColumns
  if(valWidth === 0) return

  const elm = document.getElementsByClassName('spItem-col')[0]
  if(elm !== undefined)
    elm.style.width = (valWidth) * numCols * 1.1
}


function getCurrentSet() {
  // if props.jpkg is empty, log this, and do nothing else
  if(Object.keys(props.siPkg).length === 0) {
    console.log('props.siPkg was empty')
    return
  }
// debugger
  // move props.jpkg data to local non-props object jewel_data
  // not sure this is useful, but...
  Object.assign(props.siPkg, siStore.currentSet)
}

</script>
