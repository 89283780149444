<template>
  <div class="dflexc-jcs admin-login-dialog"
    v-if="!appstore.admin_logged_in"
  >
    <h1>Admin Login</h1>
      <div class="dflexc-jcsa login-form">
        <div class="input-group">
          <label for="adminName">Name</label>
          <input type="text" v-model="form.name"/>
        </div>
        <div class="input-group">
          <label for="adminName">Password</label>
          <input type="password" v-model="form.password"/>
        </div>
        <footer class="dflexr-jce btn-row">
          <button class="btn btn-submit"
            @click="submit_login"
          >Enter the Matrix</button>
        </footer>
      </div>
    </div>

</template>

<style scoped lang="scss">
  //@import "../../assets/styles/admin";
</style>

<script setup>
import "@/assets/styles/admin.scss"
import {reactive, ref} from "vue"

const appstore = window.novasectorapp
const adminServ = appstore.services.adminServ

const form = reactive({
  name: '',
  password: ''
})

function submit_login() {
  let rdata = 'no data yet'

  const prms = adminServ.admin_login(form)
  prms.then((data) => {
        update_admin_login(data.token)
      },
      (err) => {
        console.log('submit login error:', err)
      })

}

function update_admin_login(tkn) {
  if(tkn === undefined || tkn === null) return
  appstore.setJWT(tkn)
  // decode token to get user info
  let pkg = null
  try {
    pkg = tkn.split('.')[1]
    pkg = JSON.parse(window.atob(pkg))
  } catch (e) {
    console.log('Token not encoded correctly:', e)
    console.log('token:', tkn)
    return
  }

  const user = pkg.user
  localStorage.setItem('novasector-user', JSON.stringify(user))
  console.log('user:', user)
  appstore.admin_logged_in = (user["level"] === '9');

}
</script>

