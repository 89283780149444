<template>
  <div class="dflexr-jcc" id="page-view">
    <div class="dflexc-jcc" id="prev-page">
      <unicon name="angle-left" fill="#017579"
              v-if="current_page > 0"
              width="128" height="256"
              class="btn btn-prev"
              hover-fill="cyan" @click="change_page('prev')"/>
    </div>

    <IronOFC v-if="current_page===0" />
    <IronIFC v-if="current_page===1" />





    <div class="dflexc-jcc" id="next-page">
      <unicon name="angle-right" fill="#017579"
              v-if="current_page < maxPage"
              width="128" height="256"
              class="btn btn-next"
              hover-fill="cyan" @click="change_page('next')"/>
    </div>

    <div class="return-to-empirewargames">
      <router-link class="nav-link" to="EmpireWargames">
        Return to Empire Wargames
      </router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/colors';
@import '@/assets/styles/global_variables';

.return-to-empirewargames {
  position: absolute;
  bottom: 15px;
  left: 10px;
  width: 150px;

  border: 3px outset $bg-drk20;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 12px;
  padding: .2rem .5rem;
  margin-top: 5px;
  background-color: $bg-drk10;


  .nav-link {
    color: $bg-drk20;
    text-decoration: none;

    &:hover {
      color: $fg-base;
    }
  }

}

#prev-page,
#next-page {
  width: 128px;
}

#page-view {
  width: 95%;
  margin: 0 auto;
}
</style>

<script setup>
import IronOFC from './iron_ofc_view.vue'
import IronIFC from './iron_ifc_view.vue'

import {ref} from "vue";

const current_page = ref(0)

if(get_ibkPage() !== null || get_ibkPage() !== undefined)
  current_page.value = get_ibkPage()
const maxPage = 1

function get_ibkPage() {
  return JSON.parse(localStorage.getItem('ibk_page'))
}

function set_ibkPage(pg) {
  localStorage.setItem('ibk_page', pg)
}
function change_page(val) {
  if(val === 'prev') {
    if(current_page.value > 0)
      current_page.value -= 1
  } else if(val === 'next') {
    if(current_page.value < maxPage)
      current_page.value += 1
  }

  set_ibkPage(current_page.value)
}

</script>
