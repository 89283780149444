import BaseStore from "@/lib/StoreBase";
export default class SpecialItems extends BaseStore {
  url_base = 'https://novasector.net/v1/'

  viewHeight = 0

  constructor() {
    super();

    this.currentSet.line_cnt = 1
    // change to development env if needed
    if (process.env.NODE_ENV === 'development')
      this.url_base = 'http://localhost:4200/v1/'
  }

  roll(count) {
    return this.net_fetch(count)
        .then((res) => {
          // copy values from res to the current jewel set
          this.currentSet.list = res.list


          // jewel_results is a secondary, non reactive,
          // copy of the results to be stored in special item history
          const si_results = {}
          // now assign results values to backup/history obj
          Object.assign(si_results, res)

          // add to history buffer
          this.history.push(si_results)
          this.cur_rec = this.history.length
          this.tot_rec += 1

          this.determineMaxWidth()
          this.determine_columns()
        })
  }



  async net_fetch(cnt) {

    const url = this.url_base + 'special_items/' + cnt
    return await fetch(url,{
      method: "GET"
    }).then((res) => res.json())
  }

  determineMaxWidth() {
    if(this.tmpElement === null) {
      console.log('in determineMaxWidth: tmpElement was null. cannot determine widths')
      return
    }

    const len = this.currentSet.list.length
    let hiLen = 0
    this.currentSet.line_cnt = 0
    // last element in list will have the highest value
    // debugger
    for(const val of this.currentSet.list) {
      // console.log('val:', val)
      let siVal = val.strval
      // if(siVal === undefined && val.category === 'Power Item')
      //   siVal = val.strval
      let line1 = ''
      let line2 = ''
      const idx1 = siVal.indexOf('{')

      if(idx1 === -1) {
        line1 = siVal
        val.lineCnt = 1
        this.currentSet.line_cnt += 1
      } else {
        const idx2 = siVal.indexOf('}')
        line1 = siVal.slice(0, idx1)
        line2 = siVal.slice(idx1, idx2)
        val.lineCnt = 2
        this.currentSet.line_cnt += 2
      }
      this.tmpElement.innerText = line1
      if(this.tmpElement.clientWidth > hiLen)
        hiLen = this.tmpElement.clientWidth

      if(idx1 > -1) {
        this.tmpElement.innerText = line2
        if(this.tmpElement.clientWidth > hiLen)
          hiLen = this.tmpElement.clientWidth
      }

    }
    this.currentSet.maxValWidth = hiLen
  }

  determine_columns() {
    this.tmpElement.innerText = 'test'
    const rowHeight = this.tmpElement.clientHeight + 30

    const maxLines = Math.floor((this.viewHeight / rowHeight))
    this.currentSet.numColumns = Math.floor((this.currentSet.line_cnt / maxLines))
    if(this.currentSet.numColumns === 0)
      this.currentSet.numColumns = 1
  }

}

