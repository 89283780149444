<template>
          <div class="dflexr-jcs results-item">
            <div class="side-val" :id="eleSideID">
              {{props.side}}
            </div>
            <div class="side-count" :id="eleCntID">
              {{props.val}}
            </div>
          </div>
</template>

<style scoped lang="scss">
@import '../assets/styles/colors';

.side-val, .side-count {
  font-size: 1.5rem;
  line-height: 1.8rem;
  border: 2px outset $bg-base;
  color: $fg-base;
  background-color: $bg-drk10;
  padding: .1rem .5rem;
}

.side-val {
  border-right: 2px dashed $bg-drk20;
}

.side-count {
  border-left: none;
}
</style>

<script setup>

import {computed, onMounted, onUpdated} from "vue";

const props = defineProps({
      side: Number,
      val: Number,
      sideMaxWidth: Number,
      valuesMaxWidth: Number
    }
)

// debugger
const sval = props.side
const cval = props.val

const eleSideID = computed(() => 'sideVal-' + sval)
const eleCntID = computed(() => 'cntVal-' + sval)
const color_zero = '#333'
const color_nonzero = '#AFD'

let sideEle = ''
let valEle = ''
let viewElements = ''

let totLen = '0'



function calcWidths() {
  totLen = props.sideMaxWidth + props.valuesMaxWidth + 'px'
}

function setWidths() {

  sideEle.style.width = props.sideMaxWidth + 'px'
  valEle.style.width = props.valuesMaxWidth + 'px'
  for(const ele of viewElements) {
    ele.style.width = totLen + 'px'
  }
}

onMounted(() => {
  sideEle = document.querySelector('#' + eleSideID.value)
  valEle = document.querySelector('#' + eleCntID.value)
  viewElements = document.getElementsByClassName('results-item')

  if(cval === 0)
    valEle.style.color = color_zero
  else
    valEle.style.color = color_nonzero
  calcWidths()
  setWidths()
})

onUpdated(() => {
  // debugger
  setTimeout(() => {
    if(props.val === 0) {
      valEle.style.color = color_zero
    } else {
      valEle.style.color = color_nonzero
    }
    calcWidths()
    setWidths()
  }, 60)

})
</script>
