<template>
  <div class="dflexc-jcs" id="jewel-key-legend">
    <div class="dflexr-jcs" v-for="key in nameKeys">
      <img :src="icons[key]" alt="{{key}} icon"
           :height="30"/>
      <unicon name="angle-double-right" fill="cyan"
      width="40" height="30"></unicon>
      <div class="legend-text">{{jnames[key]}}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../assets/styles/colors';
  #jewel-key-legend {
   padding: 1rem;
   overflow-y: auto;

   .legend-text {
     color: $fg-base;
   }
 }
</style>

<script setup>
const novasectorapp = window.novasectorapp
const icons = novasectorapp.jewel_icons
const jnames = {
  Amt: 'Amethyst',
  Dia: 'Diamond',
  Emr: 'Emerald',
  Sph: 'Sapphire',
  Rby: 'Ruby',
  Opl: 'Opal',
  Obs: 'Obsidian',
  Prl: 'Pearl',
}

const nameKeys = Object.keys(jnames)



</script>
