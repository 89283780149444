import { createRouter, createWebHashHistory } from 'vue-router'
import MainView from "@/views/MainView.vue"
import AdminView from "@/views/AdminView.vue"
import HedgeAssistView from "@/views/HedgeAssistView.vue"
import EmpireWargamesView from "@/views/EmpireWargamesView.vue"
import IronhedgeBookView from "@/views/ironhedgebookview.vue"
import StarCitizenView from "@/views/StarCitizenView.vue"
import IronhedgeBook from "@/views/book/ironhedge_book.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/EmpireWargames',
    name: 'EmpireWargames',
    component: EmpireWargamesView
  },
  {
    path: '/BookViewIron',
    name: 'BookViewIron',
    component: IronhedgeBook
  },
  {
    path: '/hedge_assist',
    name: 'hedge_assist',
    component: HedgeAssistView
  },
  {
    path: '/StarCitizen',
    name: 'StarCitizen',
    component: StarCitizenView
  },
  {
    path: '/IronhedgeRulebook',
    name: 'IronhedgeRulebook',
    component: IronhedgeBook
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
