<template>
<div class="dflexc-jcs warning-dialog-container">
  <div class="dialog-header">
    {{title}}
  </div>
  <div class="dialog-body">
    {{warning}}
  </div>
  <div class="dflexr-jcsa warning-footer">
    <BtnCommon btnLabel="Yes" styleclass="action-btn-cancel"
               :is-disabled="!userEditMode && !userAddMode"
               @clicked="emitSelected" />
  </div>
</div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/colors';

  .warning-dialog-container {
    position: absolute;
    left: 25%;
    top: 15%;
    width: 50%;
    height: 200px;
    border: 1px solid $bg-alert;

    font-size: 2em;

    .dialog-header {
      height: 2.2rem;
      color: $bg-notice;
      border-bottom: 2px dotted $bg-alert;
      background-color: black;
      padding: 4px 0;
    }

    .dialog-body {
      background-color: black;
      height: 100%;
      color: $bg-warning;
    }

    .warning-footer {
      height: 60px;
      background-color: $bg-base;
    }
  }
</style>

<script setup>
import BtnCommon from '@/components/common/btn-common.vue'

const props = defineProps(
    ['title', 'warning',
      'userAddMode', 'userEditMode'])

const emit = defineEmits(['selected'])

function emitSelected(res) {
  console.log('emitSelected: ', res)
  emit('selected', res)
}
</script>
