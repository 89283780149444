export class JewelGenService {
  url_base = 'https://novasector.net/v1/'

  constructor() {
    // change to development env if needed
    if (process.env.NODE_ENV === 'development')
      this.url_base = 'http://localhost:4200/v1/'
  }

  async roll(cnt) {
    const url = this.url_base + 'jewels_gen?qty=' + cnt

    return await fetch(url,{
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    }).then((res) => res.json())
  }


}
