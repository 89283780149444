<template>
  <div class="btn-panel">
    <div v-for="btn in btn_list" class="btn num-pad-btn"
      @click="$emit('btn-pressed', btn )"
    >{{btn}}</div>



  </div>

</template>

<style scoped lang="scss">
@import '../../assets/styles/colors';

  .btn-panel {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 3px;
    height: 100%;
    padding: 3px;
  }

  .num-pad-btn {
    font-size: 2em;
    color: $fg-base-drk30;
    padding-top: 8px;
    border: 1px solid $bg-drk10;

    &:hover {
      background-color: rgba(255, 255, 255, .2);
      color: $fg-base-lt30
    }
  }

</style>

<script setup>

const btn_list = [
    '7', '8', '9', 'Clr',
    '4', '5', '6', 'Del',
    '1', '2', '3', ' ',
    ' ', '0', ' ', 'Roll'
]



</script>
