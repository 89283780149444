export class AdminService {
  getHeaders = {
    "Content-type": "application/json",
  }
  postHeaders = {
    "Content-type": "application/json",
    "Authentication": "Bearer " + localStorage.getItem('novasector-jwt')
  }
  constructor() {
  }

  baseURL() {
    let url_base = 'https://novasector.net/v1/'
    // change to development env if needed
    if (process.env.NODE_ENV === 'development')
      url_base = 'http://localhost:4200/v1/'
    return url_base
  }

  async admin_login(form) {
    const url = this.baseURL() + 'admin_login'
    const pkg = JSON.stringify(form)
    const auth_tkn = localStorage.getItem('novasector-jwt')

    return await fetch(url,{
      method: "POST",
      body: pkg,
      headers: this.postHeaders
    }).then((res) => res.json(), 
        (err) => {
      console.log('ERROR: admin login fetch user list: ', err)
    })
  }

  async admin_logout() {
    const url = this.baseURL() + 'admin_logout'
    await fetch(url, {
      method: 'POST',
      headers: this.postHeaders
    }).then((res) => res.json())
        .then((pkg) => console.log('logout status:', pkg.message))
  }

  add_user(user) {
    const url = this.baseURL() + 'admin/user/add'

    return fetch(url, {
      method: 'POST',
      headers: this.postHeaders,
      body: JSON.stringify({ user: user })
    }).then((res) => res.json())

  }

  remove_user(user) {
    const url = this.baseURL() + 'admin/user/remove'
    console.log('admin service: remove user commit!', user)

    return fetch(url, {
      method: 'POST',
      headers: this.postHeaders,
      body: JSON.stringify({user})
    }).then((res) => res.json())
  }

  update_user(user) {
    const url = this.baseURL() + 'admin/user/update'

    return fetch(url, {
      method: 'POST',
      headers: this.postHeaders,
      body: JSON.stringify({ user: user })
    }).then((res) => res.json())
  }

}


