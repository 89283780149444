<template>
  <div class="dflexr-jcs jewel-roller-results" id="jewel-results">
    <div class="dflexc-jcs input-panel">
      <div class="lbl-numpad-generate">
        Enter the number of jewels to generate
      </div>
      <NumPad @generate="(cnt) => generate(cnt)" :store="jewelStore"/>
      <JewelKeyLegend/>
    </div>

    <div class="results-container">
      <div class="dlfexc-jcs jewel-results-wrapper">
        <JewelResultsPanel :jpkg="jpkg"/>
      </div>

      <div class="dflexr-jcsb jewel-footer">
        <div class="dflexr-jcsb jewel-history-panel">
          <ResultsHistoryControl :cur_rec="meta.cur_rec" :tot_rec="meta.tot_rec"
                              v-on:hist-prev="history_step_one(-1)"
                              v-on:hist-next="history_step_one(1)"
                              v-on:hist-first="history_goto('first')"
                              v-on:hist-last="history_goto('last')"
          />
        </div>

        <div class="dflexr-jcc jewel-info">
          <span  v-if="jpkg.qty > 0">
            <span class="txt-jewels-gen">Jewels Generated:</span> {{jpkg.qty}}
          </span>
        </div>
        <div class="dflexr-jcc jewel-totalvalue">
          <span class="txt-jewels-total" v-if="jpkg.total_val > 0">total:</span> {{ jpkg.total_val }}s
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">

</style>

<script setup>
import '../assets/styles/jewel-roller-view.scss'
import '../assets/styles/history_control.scss'
import NumPad from '../components/numpad/num_pad.vue'
import JewelResultsPanel from '../components/jewels/Jewel_results_panel.vue'
import JewelKeyLegend from '../components/jewels/jewel_key_legend.vue'
import ResultsHistoryControl from "@/components/results_history_control.vue";
import {onMounted, reactive} from "vue";

const jpkg = reactive({
  list: [],
  qty: 0,
  total_val: 0,
  maxValWidth: 0,
  maxCntWidth: 0
})

const meta = reactive({
  cur_rec: 0,
  tot_rec: 0
})
const novasectorapp = window.novasectorapp

const jewels = novasectorapp.jewels
const jewelStore = novasectorapp.jewelStore

// let tmpElement = document.getElementById('temp_element')

onMounted(() => {
  update_jewel_results()
})


async function generate(cnt) {
  await jewelStore.roll(cnt)
      .then(() => update_jewel_results())
}


function update_jewel_results () {
  Object.assign(jpkg, jewelStore.currentSet)
  meta.cur_rec = jewelStore.cur_rec
  meta.tot_rec = jewelStore.tot_rec
}


function history_step_one(step) {
  jewelStore.history_step(step)
  update_jewel_results()
}


function history_goto(val) {
  jewelStore.history_goto(val)
  update_jewel_results()
}


</script>
