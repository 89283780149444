<template>
  <div class="dflexr-jcs" :id="item_id">
    <div class="jvalue jwl-val-results-item" :id="jval_id">{{item.jvalue}}s</div>
    <div class="jcount jwl-cnt-results-item" :id="jcnt_id">{{item.count}}</div>
    <div class="dflexr-jcs jtypes jwl-jtypes-results-item" :id="jtyp_id">
      <div class="dflexr-jcs type-grp" v-for="key in Object.keys(item.types)">
        <img :src="icons[key]" :alt="key + ' icon'" height="24">
        {{item.types[key]}}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../assets/styles/colors';

.jvalue, .jcount, .jtypes {
  font-size: 30px;
  border: 1px solid $bg-drk10;
  padding: .25rem .1rem;
  margin-bottom: 1px;
}

.jvalue {
  border-bottom-left-radius: 5px;
  border-right: 2px dashed $bg-drk10;
  color: $fg-base;
}

.jcount {
  border-left: none;
  color: $fg-base-drk30;
}

.jtypes {
  flex: 1;
}

.type-grp {
  padding-top: .1rem;
  margin-right: 10px;
  color: $bg-base;

  img {
    margin-top: 3px;
  }
}

</style>

<script setup>
import {computed, onMounted, onUpdated} from "vue"
const novasector_app = window.novasectorapp

const icons = novasector_app.jewel_icons

const props = defineProps(['item', 'maxValWidth', 'maxCntWidth'])
const jval = props.item.jvalue
const item_id = computed(() => 'jewel-item-' + jval)
const jval_id = computed(() => 'item-jval-' + jval)
const jcnt_id = computed(() => 'item-jcnt-' + jval)
const jtyp_id = computed(() => 'item-jtyp-' + jval)

const jtypes = {}


onMounted(() => adjust_widths())


onUpdated(() => {
  adjust_widths()
})


function adjust_widths() {
  document.getElementById(jval_id.value).style.width = props.maxValWidth + 'px'
  document.getElementById(jcnt_id.value).style.width = props.maxCntWidth + 'px'

  for(const tp_key in props.item.types) {
    jtypes[tp_key]
  }
}

</script>
