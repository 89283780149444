<template>
  <AdminLogin v-if="!isAdminLoggedIn"/>
  <div class="dflexr-jcc admin-mainview" v-if="isAdminLoggedIn" >
    <div class="dflexc-jcs" id="table-select-col">
      <BtnCommon btnLabel="User" styleclass="TableSelect"
                      @clicked="(table) => activeTable.value = table" />
    </div>
    <div class="dflexc-jcs" id="table-view-col" >
      <UserTableView v-if="activeTable === 'user'" />
    </div>


  </div>
</template>

<style scoped lang="scss">
@import "@/assets/styles/admin";
</style>

<script setup>
import AdminLogin from "@/components/dialogs/AdminLogin.vue";
import {computed, onBeforeMount, reactive, ref} from "vue";

import BtnCommon from "@/components/common/btn-common.vue";
import UserTableView from "@/components/admin/user-table-view.vue";

const activeTable = ref('user')

const isAdminLoggedIn = computed(() => {
  console.log('admin logged in:', window.novasectorapp.admin_logged_in)
  return window.novasectorapp.admin_logged_in
})

onBeforeMount(() => {
  // debugger
  const user = JSON.parse(localStorage.getItem('novasector-user'))
  if(user === null)
    return
  window.novasectorapp.admin_logged_in = (user.level === '9')
})

function selectTable(table) {
  activeTable.value = table
}

</script>
