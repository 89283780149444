export default class BaseStore {
  currentSet = {
    list: [],
    qty: 0,
    total_val: 0,
    maxValWidth: 0,
    maxCntWidth: 0
  }
  history = []
  cur_rec = 0
  tot_rec = 0
  tmpElement = null

  constructor() {
    this.tmpElement = document.getElementById('temp_element')
    if (this.tmpElement === null){
      this.createTmpElement()
      document.body.append(this.tmpElement)
    }
  }

  createTmpElement() {
    this.tmpElement = document.getElementById('temp_element')
    if(this.tmpElement === null) {
      // tmpEle is positioned absolutely 10000px to the left.
      // It is not ment to be view; used to determine height with whatever
      // font is being used.
      this.tmpElement = document.createElement('div')
      this.tmpElement.innerText = 'test'
      this.tmpElement.className = 'tmp-results-item'
      this.tmpElement.id = 'temp_element'
    }


  }


  history_step(step) {
    const hlen = this.history.length
    this.cur_rec += step
    if(this.cur_rec < 1)
      this.cur_rec = 1
    if(this.cur_rec > hlen)
      this.cur_rec = hlen

    Object.assign(this.currentSet, this.history[this.cur_rec-1])
  }

  history_goto(val) {
    const len = this.history.length
    if(val === 'first')
      this.cur_rec = 1
    else if (val === 'last')
      this.cur_rec = len

    Object.assign(this.currentSet, this.history[this.cur_rec-1])
  }

}
