<template>
<div class="dflexc-jcs user-table-view">
  <div class="dflexc-jcs data-entry-form">
    <h1 class="data-entry">Data Entry</h1>
    <div class="dflexr-jcsa user-data-form">
      <div class="input-group" style="flex: 0 1;">
        <label for="user-id">ID</label>
        <input type="text" name="user-id" id="user-id"
               v-model="userData.id">
      </div>
      <div class="input-group">
        <label for="user-name">Name</label>
        <input type="text" name="user-name" id="user-name"
               v-model="userData.name">
      </div>
      <div class="input-group" style="flex: 0 1;">
        <label for="user-lvl">Level</label>
        <input type="text" name="user-lvl" id="user-lvl"
               v-model="userData.level">
      </div>
    </div>
    <div class="dflexr-jcc password-row" :class="{'password-box-open': userAddMode===true}">
      <div class="input-group" id="input-group-pass1">
        <label for="pass1">Password</label>
        <input type="password" id="pass1" name="pass1"
               v-model="userData.password">
      </div>
      <div class="input-group" id="input-group-pass2">
        <label for="pass2">Password Confirmation</label>
        <input type="password" id="pass2" name="pass2"
               v-model="userData.password_confirmation">
      </div>
    </div>
    <div class="dflexr-jcsb data-entry-action-row">
      <div class="dflexr-jcs" id="user-form-action-row">
        <BtnCommon btnLabel="Add" styleclass="action-btn-add"
                   :isDisabled="addBtnDisabled"
                   @clicked="() => addUser()" />
        <BtnCommon btnLabel="Save" styleclass="action-btn-save"
                   :is-disabled="!userEditMode"
                   @clicked="() => updateUser()" />
        <BtnCommon btnLabel="Cancel" styleclass="action-btn-cancel"
                   :is-disabled="!userEditMode && !userAddMode"
                   @clicked="() => cancel_edit()" />
      </div>
      <div class="dflexr-jce" id="btn-remove-user-row">
        <BtnCommon btnLabel="Remove" styleclass="action-btn-remove"
                   :is-disabled="!userEditMode" @clicked="() => remove_user()"
                     />
      </div>
    </div>
    <WarningDialog title="Delete User" :warning="delete_warning"
                   :userEditMode="{userEditMode}"
                   :addUserMode="{userAddMode}"
                   v-if="showWarning && userEditMode"
                   @selected="remove_user_commit"  />
  </div>
  <div class="user-table-list-view">
    <div class="dflexc-jcs" id="user-data">
      <div class="dflexr-jcs user-table-header">
        <div class="header-txt" id="user-table-header-name">
          Name
        </div>
        <div class="header-txt" id="user-table-header-id">
          Level
        </div>
        <div class="header-txt" id="user-table-header-actions">
          Actions
        </div>
      </div>
      <div class="dflexr-jcs user-data-entry"
           v-for="(user, idx) in userList.all" :key="user.name" >

        <div class="user-data-row user-data-name" :class="{striped: idx % 2 === 0}">
          {{user.name}}
        </div>
        <div class="user-data-row user-data-level" :class="{striped: idx % 2 === 0}">
          {{user.level}}
        </div>
        <div class="user-data-row user-data-actions" :class="{striped: idx % 2 === 0}"
             @click="editUser(idx)" @selected="remove_user_commit">
          Edit
        </div>

      </div>
    </div>
  </div>

</div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/admin/user-table-input-form';
@import '@/assets/styles/admin/user-table-data-lines';

</style>

<script setup>
import BtnCommon from "@/components/common/btn-common.vue";
import WarningDialog from "@/components/dialogs/warning-dialog.vue";

import {computed, onBeforeMount, onMounted, reactive, ref} from "vue";

const store = window.novasectorapp
const adminServ = store.services.adminServ

const userList = reactive({all: []})
const userData = reactive({
  id: 0,
  name: '',
  level: '0',
  password: '',
  password_confirmation: ''
})

const addBtnDisabled = computed(() => {
  let isDisabled = true
  if(userData.id === 0 && userData.name !== '') {
    userAddMode.value = true
    if(userData.password === userData.password_confirmation &&
        (userData.password !== '' && userData.password_confirmation !== '')) {
      isDisabled = false
    }
  } else {
    userAddMode.value = false
  }
  return isDisabled
})

let showWarning = ref(false)
const delete_warning = computed(() => {
  return 'Are you sure you want to delete the user: \n' + userData.name + '?'
})

const userAddMode = ref(false)
const userEditMode = ref (false)
let userEditIdx = 0

onBeforeMount(() => {
  fetchUsers()
})

onMounted(() => {

})

function fetchUsers() {
  let url = store.baseURL()

  url += "admin_fetch/users"
  const jwt = store.getJWT()
  fetch(url,{
    method: "GET",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "Autorization": "Token " + jwt
    }
  }).then((res) => res.json(),
      (err) => {
        console.log('Error getting user list:', err.errors)})
      .then((pkg) => {
        console.log('fetch all users', pkg)
        store.setJWT(pkg.jwt)
        userList.all = pkg.user_list
      })
}

function addUser() {
  userAddMode.value = true
  const pkg = {}
  Object.assign(pkg, userData)
  adminServ.add_user(pkg)
      .then((pkg) => {
        const u = pkg.new_user

        cancel_edit() // cancel edit clears user edit form
        fetchUsers()
        console.log('add user response', pkg)
      })


  console.log('user data:', userData)
}

function remove_user() {
  showWarning.value = true

}

function remove_user_commit(dialogSel) {
  console.log('remove user commit:', dialogSel)
  if(dialogSel === 'Yes') {
    console.log('commiting user removal', userData)
    const pkg = {}
    Object.assign(pkg, userData)
    adminServ.remove_user(pkg)
        .then((rPkg) => {
          fetchUsers()
        })
  } else
    console.log('User removal aborted');

  cancel_edit()
  showWarning.value = false
}

function editUser(idx) {
  console.log('edit user', userList.all[idx])
  Object.assign(userData, userList.all[idx])
  userEditMode.value = true
}

function updateUser() {
  const user = {}
  Object.assign(user, userData)

  adminServ.update_user(user)
      .then((pkg) => {
        console.log('update user response pkg:', pkg)
        Object.assign(userList.all[userEditIdx], pkg)
      })
}

function cancel_edit() {
  userData.id = 0
  userData.name = ''
  userData.level = '0'
  userData.password = ''
  userData.password_confirmation = ''
  userEditMode.value = false;
  userAddMode.value = false;
}
</script>
