<template>
  <div class="dflexr-jcs special-item-results results-view" id="special-item-results">
    <div class="dflexc-jcs input-panel">
      <div class="lbl-numpad-generate">
        Enter the number of Special Items to generate
      </div>
      <NumPad @generate="(cnt) => generate(cnt)" :store="siStore"/>
    </div>
    <div class="dflexc-jcs results-container">
      <siResultsPanel :siPkg="siPkg"/>
      <div class="dflexr-jcc special-item-footer">
        <ResultsHistoryControl :cur_rec="meta.cur_rec" :tot_rec="meta.tot_rec"
                               v-on:hist-prev="history_step_one(-1)"
                               v-on:hist-next="history_step_one(1)"
                               v-on:hist-first="history_goto('first')"
                               v-on:hist-last="history_goto('last')"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../assets/styles/colors';

.results-view {

  .special-item-footer {
    border: 1px solid $bg-base;
    height: 36px;
    min-height: 30px;
    max-height: 100%;
    width: 50%;
    margin: auto;
    font-size: 1.5em;

    .history-controls {

    }
  }

}



.results-container {
  flex: 1;
  //height: 100%;
  max-height: calc(100%);
  overflow-y: auto;
}
</style>

<script setup>
// import '../assets/styles/special-item-view.scss'

import NumPad from '../components/numpad/num_pad.vue'
import siResultsPanel from '../components/SpecialItems/si_results_panel.vue'
import ResultsHistoryControl from "@/components/results_history_control.vue";

import {onMounted, onUpdated, reactive} from "vue";
const novasectorapp = window.novasectorapp

const siStore = novasectorapp.siStore

const siPkg = reactive({
  list: [],
  qty: 0,
  maxValWidth: 0,
  viewHeight: 0
})

const meta = reactive({
  cur_rec: 0,
  tot_rec: 0
})

onMounted(() => {
  const view = document.getElementsByClassName('spItem-results-panel')[0]
  siStore.viewHeight = view.clientHeight
  siStore.determine_columns()
  update_results()
})


async function generate(cnt) {
  await siStore.roll(cnt)
      .then(() => update_results())
}

onUpdated(() => {
  update_results()
})

function prep_view() {
  const cSet = siStore.currentSet

  const numCols = cSet.numColumns
  const width = cSet.maxValWidth * numCols * 1.1

  const viewCol = document.getElementsByClassName('spItem-col')[0]
  if(viewCol !== undefined)
    viewCol.style.width = width + 'px'


}

function update_results() {

  Object.assign(siPkg, siStore.currentSet)
  // debugger
  meta.cur_rec = siStore.cur_rec
  meta.tot_rec = siStore.tot_rec
  prep_view()
}

function history_step_one(step) {
  siStore.history_step(step)
  siStore.determineMaxWidth()
  siStore.determine_columns()
  update_results()
}


function history_goto(val) {
  siStore.history_goto(val)
  siStore.determineMaxWidth()
  siStore.determine_columns()
  update_results()
}

</script>
