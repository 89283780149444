<template>
  <div class="dflexc-jcs" id="inside-front-cover">
    <h3 class="ew-bold page-content fg-red">IRONHEDGE©  TABLE OF CONTENTS</h3>
    <div class="dflexr-jcc" id="conents-table">
      <div class="dflexc-jcs contents-num-col" id="contents-left-num-col">
        <div class="ew-med center fg-blue">i</div>
        <div class="ew-med center fg-blue">ii</div>
        <div class="ew-med center fg-blue">1</div>
        <div class="ew-med center fg-blue">4</div>
        <div class="ew-med center fg-blue">6</div>
        <div class="ew-med center fg-blue">8</div>
        <div class="ew-med center fg-blue">10</div>
        <div class="ew-med center fg-blue">11</div>
        <div class="ew-med center fg-blue">12</div>
        <div class="ew-med center fg-blue">13</div>
        <div class="ew-med center fg-blue">16</div>
        <div class="ew-med center fg-blue">17</div>
      </div>

      <div class="dflexc-jcs" id="contents-left-txt-col">
        <div class="ew-med left fg-text">Ironworld© Brief</div>
        <div class="ew-med left fg-text">Codex Ironepic</div>
        <div class="ew-med left fg-text">Play / Terms / Functions / Saves</div>
        <div class="ew-med left fg-text">Ironhedge Characters</div>
        <div class="ew-med left fg-text">Adventure Setup/Prices</div>
        <div class="ew-med left fg-text">Individual Combat</div>
        <div class="ew-med left fg-text">Missiles / Subdual</div>
        <div class="ew-med left fg-text">Large Scale Combat</div>
        <div class="ew-med left fg-text">Naval Combat / Building</div>
        <div class="ew-med left fg-text">Spells/Potions/Blessings</div>
        <div class="ew-med left fg-text">Weather / Language / Movement</div>
        <div class="ew-med left fg-text">Debris / Shares / Jewels</div>
      </div>

      <div class="dflexc-jcs contents-num-col" id="contents-right-num-col">
        <div class="ew-med center fg-blue">18</div>
        <div class="ew-med center fg-blue">22</div>
        <div class="ew-med center fg-blue">26</div>
        <div class="ew-med center fg-blue">28</div>
        <div class="ew-med center fg-blue">30</div>
        <div class="ew-med center fg-blue">32</div>
        <div class="ew-med center fg-blue">34</div>
        <div class="ew-med center fg-blue">36</div>
        <div class="ew-med center fg-blue">40</div>
        <div class="ew-med center fg-blue">41</div>
        <div class="ew-med center fg-blue">42</div>
        <div class="ew-med center fg-blue">ibc</div>
      </div>

      <div class="dflexc-jcs" id="contents-right-txt-col">
        <div class="ew-med left fg-text">Encounters / Hostility</div>
        <div class="ew-med left fg-text">Men / Dwarfs / Elfs</div>
        <div class="ew-med left fg-text">Menlike / Riding Animals</div>
        <div class="ew-med left fg-text">Wild & Game Animals / Pests</div>
        <div class="ew-med left fg-text">Reptiles / Giant Pests</div>
        <div class="ew-med left fg-text">Spirits / Undead / Invisibility</div>
        <div class="ew-med left fg-text">Sprites / Elementals / Silicon</div>
        <div class="ew-med left fg-text">Special Items</div>
        <div class="ew-med left fg-text">Hiring / Guilds</div>
        <div class="ew-med left fg-text">Universities / Books</div>
        <div class="ew-med left fg-text">Worlds / Referee Tips</div>
        <div class="ew-med left fg-text">Procedure of Play</div>
      </div>
    </div>

    <div class="page-content ew-med fg-text" id="much-thanks">
      <span class="ew-med fg-red">MUCH THANKS</span> to the idiots who helped me develop
      these rules.
    </div>
    <div class="page-content ew-med fg-text" id="honorable-mentions" >
      <span class="ew-med fg-blue">Honorable Mentions:</span> Ron Sais, Joe Brooke, Tod Casselman,
      Jeff Brook, Jim Bleeker, Dan Stapert, Ernie Moore, and many other who would sue me if
      I called them idiots
    </div>
    <div class="ew-med-wide fg-text" id="ifc-riddle">
      <span>Who Knows what passes the secret walls?</span><br>
      <span>Seldom to be heard in silent halls?</span><br>
      <span>Who can crack the inscriptions within?</span><br>
      <span>Certainly not ordinary men...</span>
    </div>
    <div class="page-conent ew-med fg-text justify" id="empire-wargames-promo"
    style="margin-top: 10px;">
      <span class="fg-red">EMPIRE WARGAMES</span> is dedicated to producing high quality
      role playing games for intelligent people in a concise pocketsize format. Each with
      intercompatible rules. They are:
    </div>
    <div class="dflexr-jcsb ew-med fg-text" id="gamelist" style="width: 90%; margin: 0 auto;">
      <div class="dflexc-jcs">
        <div class="left"><span class="fg-blue">Ironhedge©</span>- Ancient Fantasy </div>
        <div class="left"><span class="fg-blue">Westhedge©</span>- Old West </div>
      </div>
      <div class="dflexc-jcs">
        <div class="left"><span class="fg-blue">Ganghedge©</span>- Inner City</div>
        <div class="left"><span class="fg-blue">Starhedge© </span>- Futuristic</div>
      </div>
    </div>
    <div class="page-content ew-med fg-text left normal-content" id="exchange-rate">
      <span class="fg-red">Exchange Rate 1989: </span>
      1 Copper Coin  = $1.33 (inflation changes this). Take 90% discount for payment
      in real U.S. Silver Coin
    </div>
    <div class="page-content ew-med fg-text left normal-content">
      <span class="fg-blue">Price List: </span>
      Send for a free Price List of Games / Supplies / New underworlds. U.S. Postal
      Money Orders or cash preferred.
    </div>
    <div class="page-content ew-med fg-blue">
      Complete Ironhedge - Ironworld Game: <span class="fg-text">24c</span><br>
      Basic Game (Ironhedge Rulebook & Cards): <span class="fg-text">5c</span><br>
      <div class="dflexr-jcsa" style="width: 75%; margin: 0 auto">
        <div>36 Character Cards: <span class="fg-text">2c</span></div>
        <div>36 Encounter Cards: <span class="fg-text">2c</span></div>
      </div>
    </div>
    <div class="page-content ew-med fg-text left normal-content" id="disclaimer">
      <span class="fg-red justify">
        Disclaimer:
      </span>
      Scum thinking this game is linked to Satan worship, demonism, drug use, or similar
      absurdities are stupid idiots
    </div>
    <div class="page-content ew-med fg-text left normal-content" id="disclaimer">
      <span class="fg-blue justify">
        Warning:
      </span>
      This game contains free thought, free trade, low taxes, local government,
      unrestricted travel, class and merit systems, upward mobility, and other
      anti-communist muse; it may make you think for yoursefl and turn you into
      an individual.
    </div>
    <div class="dflexr-jce fg-red page-content ew-medieval norma-content"
        style="width: 84%;">
      & Coins
    </div>
    <h2 class="dflexr-jcs fg-red ew-medieval norma-content"
         style="width: 80%; margin: 0 auto; margin-top: -15px">
      Empire Wargames
    </h2>
    <div class="ew-med fg-blue" style="margin-top: -10px;">P.O. Box 401</div>
    <div class="ew-med fg-blue">Empire Michigan 49630</div>
    <div class="ew-med fg-red">Bk1 - Copyright  1989 by John Brooke</div>
    <div class="sm-txt">PREVIOUS EDITIONS COPYRIGHT ©1979 ©1983 ©1985 & ©1987</div>
    <div class="sm-txt">P.O. BOX 94, MARNE MI. 49434</div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/book/bookview';
</style>

<script setup>

</script>
