export class DiceButtonService {
  url_base = 'https://novasector.net/v1/'
  constructor() {
    // change to development env if needed
    if(process.env.NODE_ENV === 'development')
      this.url_base = 'http://localhost:4200/v1/'
  }

  async button_labels() {
    const url = this.url_base + 'dice_labels'

    return await fetch(url,{
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    }).then((res) => res.json())
  }

  async dice_roll(dstr) {
    const url = this.url_base + 'dice_roll'
    return await fetch(url,{
      method: "POST",
      body: JSON.stringify({roll: dstr}),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    }).then((res) => res.json())
  }
}

export class DiceGenService {

  url_base = 'https://novasector.net/v1/'
  constructor() {
    // change to development env if needed
    if(process.env.NODE_ENV === 'development')
      this.url_base = 'http://localhost:4200/v1/'
  }

  async roll(dstr) {
      const url = this.url_base + 'dice_roll'
      return await fetch(url,{
        method: "POST",
        body: JSON.stringify({roll: dstr}),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }).then((res) => res.json())
  }

}
