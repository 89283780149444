import BaseStore from "@/lib/StoreBase";
import {JewelGenService} from '@/services/jewels_service'
export default class Jewels extends BaseStore {
  jewelServ= new JewelGenService()

  // element is an off screen element to determine widths
  constructor() {
    super();
  }

  roll(cnt) {
    return this.jewelServ.roll(cnt)
        .then((res) => {
          // copy values from res to the current jewel set
          Object.assign(this.currentSet, res)

          // jewel_results is a secondary, non reactive,
          // copy of the results to be stored in jewel history
          const jewel_results = {}

          // now assign results to backup/history obj
          Object.assign(jewel_results, res)

          // add to history buffer
          this.history.push(jewel_results)
          this.cur_rec = this.history.length
          this.tot_rec += 1
          // console.log('Jewels generated:', jewel_results)

          this.determineMaxWidth()
        })
  }

  determineMaxWidth() {
    if(this.tmpElement === null) {
      console.log('in determineMaxWidth: tmpElement was null. cannot determine widths')
      return
    }
    const len = this.currentSet.list.length
    // last element in list will have the highest value
    const jval = this.currentSet.list[len - 1][0].jvalue
    this.tmpElement.innerText = jval + 's'
    this.currentSet.maxValWidth = this.tmpElement.clientWidth

    // now go through the counts and get max count
    // do determine max width of content.
    let hiCount = 0
    for(const j of this.currentSet.list) {
      if(j[0].count > hiCount)
        hiCount = j[0].count
    }
    this.tmpElement.innerText = hiCount
    this.currentSet.maxCntWidth = this.tmpElement.clientWidth
  }
}

