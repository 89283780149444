<template>
  <div class="dflexc-jcs" id="outside-front-cover">
    <h3 class="ew-bold2 fg-red">
      Ancient Fantasy Role Playing Adventure
    </h3>
    <h1 class="ew-medieval fg-olive">
      Ironhedge
    </h1>
    <h1 class="ew-medieval fg-olive">
      Manifesto
    </h1>
    <div class="dflexr-jcc" id="symbol-row">
      <img :src="rain_img" alt="rain drops"
           :width="symbol_size" :height="symbol_size">
      <div class="dflexc-jcs" id="symbol-col">
        <img :src="earthsym_img" alt="earth symbol" :width="symbol_size">
        <img :src="airsym_img" alt="earth symbol" :width="symbol_size">
      </div>
      <img :src="campfire_img" alt="campfire"
           :width="symbol_size" :height="symbol_size">
    </div>
    <h3 class="ew-bold2 fg-red" style="width: 90%; margin: 0 auto;" >
      Game of Swordfights, Thiefery, Magic, Treasure, Monsters, Power & Lawlessness
    </h3>
    <h3 class="ew-bold2 fg-blue">
      (Not for Idiots)
    </h3>
    <h2 class="ew-medieval fg-olive">
      Empire Wargames
    </h2>
  </div>

</template>

<style scoped lang="scss">
@import '@/assets/styles/book/bookview';
</style>

<script setup>
import rain_img from '@/assets/images/rain_drops01_800.png'
import earthsym_img from '@/assets/images/earth_symbol1_800.png'
import airsym_img from '@/assets/images/air_symbol01_800.png'
import campfire_img from '@/assets/images/camp_fire01_800.png'

const symbol_size = 150

</script>
