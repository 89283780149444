<template>
<div class="dflexc-jcs spItem-container">
  <div class=" spItem-line1"
       :class="{isPowerItem: isPowerItem}">
    {{line1}}
  </div>
  <div v-if="line2.length > 0" class="spItem-line2"
       :class="{isPowerItem: isPowerItem}">
    {{line2}}
  </div>
</div>
</template>

<style scoped lang="scss">
@import '../../assets/styles/colors';

.spItem-container {
  font-size: 1.5rem;
  color: $fg-base;
  max-height: fit-content;
  line-height: 1.8rem;
  border: 3px outset $bg-base;
  background-color: $bg-drk10;
  border-radius: 8px 3px 12px 4px;

  padding: 2px .6rem;

  .spItem-line1 {
    white-space: nowrap;
  }
}

.isPowerItem {
  color: #9900ff;
}

</style>

<script setup>

import {computed, onMounted, ref} from "vue";

const props = defineProps(['item', 'maxValWidth'])


const isPowerItem = props.item.category === 'Power Item'
const line = ref(props.item.strval)
if(line.value === undefined)
  line.value = props.item.strval
const line1 = ref('')
const line2 = ref('')
// debugger
const idx = line.value.indexOf('{')
if(idx > -1) {
  const idx2 = line.value.indexOf('}')
  line1.value = line.value.slice(0, idx)
  line2.value = line.value.slice(idx+1, idx2)
} else {
  line1.value = line.value
}
const l2 = computed(() => line2.value.length > 0)



onMounted(() => {
  const elem = document.getElementsByClassName('spItem-container')
  const maxWidth = props.maxValWidth
  console.log('props max val width:', props.maxValWidth)
  for(const e of elem) {
    e.style.width = (maxWidth * 1.1) + 'px'
  }

})


</script>
