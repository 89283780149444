import BaseStore from "@/lib/StoreBase";
import { DiceButtonService, DiceGenService } from '@/services/dice_service'
import {reactive} from "vue";

export default class Dice extends BaseStore {
  diceGenServ= new DiceGenService()
  diceBtnServ = new DiceButtonService()

  tmpElement = null
  btnLabels = []

  l =1
  m = 1
  r = 1


  constructor() {
    super();
  }

  setTmpElement(element) {
    this.tmpElement = element
  }

  getButtonLabels() {
    if(this.btnLabels.length !== 0)
      return new Promise((resolve) => this.btnLabels)
    else
      return this.diceBtnServ.button_labels()
  }

  roll(dstr) {
    return this.diceGenServ.roll(dstr)
        .then((pkg) => {
          const dresults = {
            total: 0,
            list: [],
            // left, mid, right values of dstr
            l: 1, m: 1, r: 6
          }
          pkg['dstr'] = dstr
          Object.assign(this.currentSet, pkg)

          Object.assign(dresults, pkg)
          this.history.push(dresults)

          this.cur_rec = this.history.length
          this.determineMaxWidth()
          return pkg
        })
  }

  determineMaxWidth() {
    if(this.tmpElement === null) {
      console.log('in determineMaxWidth: tmpElement was null.')
      this.createTmpElement()
      if(this.tmpElement === null)
        console.log('in determineMaxWidth::',
            'tmpElement still null after second attempt to creat it')
    }
    this.tmpElement.innerText = (this.m * this.r).toString()
    this.currentSet.maxValWidth = this.tmpElement.clientWidth

    let maxVal = 0
    const vals = this.currentSet.list
    for(const val of vals) {
      if(val > maxVal) {
        maxVal = val
      }
    }

    this.tmpElement.innerText = maxVal.toString()
    this.currentSet.maxCntWidth = this.tmpElement.clientWidth
  }

  pull_from_history() {
    Object.assign(this.currentSet, this.history[this.cur_rec-1])
  }
}
