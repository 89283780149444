<template>
  <div class="dflexr-jcse history-controls">
    <div class="goto-first-record btn btn-ctl"
      @click="$emit('HistFirst')"
    >
      <unicon name="angle-double-left" fill="cyan"
              width="40" height="30"></unicon>
    </div>
    <div class="prev-record btn btn-ctl"
        @click="$emit('HistPrev')"
    >
      <unicon name="angle-left" fill="cyan"
              width="40" height="30"></unicon>
    </div>
    <div class="num_records">
      {{record_info}}
    </div>
    <div class="next-record btn btn-ctl"
         @click="$emit('HistNext')"
    >
      <unicon name="angle-right" fill="cyan"
              width="40" height="30"></unicon>
    </div>
    <div class="goto-current-record btn btn-ctl"
      @click="$emit('HistLast')"
    >
      <unicon name="angle-double-right" fill="cyan"
              width="40" height="30"></unicon>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../assets/styles/history_control';
</style>

<script setup>

import {computed} from "vue";

const props = defineProps(['cur_rec', 'tot_rec'])

const record_info = computed(() =>
    props.cur_rec + ' / ' + props.tot_rec)

</script>
