<template>
  <div class="dflexr-jcs" id="jewel-results-panel">
    <div class="dflexc-jcs" id="jewel-col" v-if="ready">
      <Item v-for="item in jpkg.list" :item="item[0]"
            :key="item[0].value"
            :maxValWidth="jpkg.maxValWidth"
            :maxCntWidth="jpkg.maxCntWidth"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../assets/styles/colors';
@import '../../assets/styles/jewel-roller-view';

</style>

<script setup>

import Item from './jewel_results_item.vue'
import {onMounted, onUpdated, ref} from "vue";

let jStore = null
const props = defineProps(['jpkg'])
const ready = ref(false)

onMounted(() => {
  jStore = window.novasectorapp.jewelStore
  getCurrentSet()
  ready.value = true
})

onUpdated(() => {
  getCurrentSet()
})

function getCurrentSet() {
  // if props.jpkg is empty, log this, and do nothing else
  if(Object.keys(props.jpkg).length === 0) {
    console.log('props.jpkg was empty')
    return
  }
  // adjust max widths if not empty
  if(props.jpkg.qty > 0)
    jStore.determineMaxWidth()

  // move props.jpkg data to local non-props object jewel_data
  // not sure this is useful, but...
  Object.assign(props.jpkg, jStore.currentSet)


}

</script>
