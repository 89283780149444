<template>
  <h1>Star Citizen Adventures</h1>
  <a href="https://novasector.net/videos/StarCitizen/StarCitizen_MiningRescueOP_funny.m4v">Star Citizen Mining/Rescue OP (Funny)</a>
</template>

<style scoped lang="scss">
@import "../assets/styles/colors";

  h1 {
    color: $fg-base;
  }
</style>

<script setup>

</script>
