<template>
  <div class="dflexr-jcs dice-results-view" id="dice_results_view">
    <div class="dflexc-jcs dice-buttons-col">
      <div class="dflex-row" id="manual-entry-row">
        <input type="text" class="dice-input" placeholder="Manual entry"
               @keyup.enter="manual_roll(manual_entry)" v-model="manual_entry"
               spellcheck="false"
        >

        <div class="btn-unicon-return">
          <unicon name="enter" fill="#017579" height="16" class="btn btn-return"
                  hover-fill="cyan"
                  v-if="manual_entry_entered" @click="dice_roll(manual_entry)"/>
        </div>
      </div>

      <div v-for="lbl of btn_labels" class="btn-dice"
           @click="dice_roll(lbl)">
        {{lbl}}
      </div>
    </div>
    <div class="dflexc-jcc results-container" id="res-container">
        <div class="dice-results-wrapper">
          <div class="dflexc-jcc dice-results-cols" id="results-cols">
            <RollResultsItem v-for="(val, index) in dice_results.list"
                :side="index+meta.MultiSetOffset+1" :val="val"
                :sideMaxWidth="meta.sideMaxWidth" :valuesMaxWidth="meta.valuesMaxWidth"
                :key="index"
            />
          </div>
        </div>
        <div class="dflexr-jcsb dice-results-footer">
          <DiceHistoryControl :cur_rec="meta.cur_rec" :tot_rec="meta.tot_rec"
            v-on:hist-prev="history_step(-1)"
            v-on:hist-next="history_step(1)"
            v-on:hist-first="history_goto('first')"
            v-on:hist-last="history_goto('last')"
          />
          <div class="dice-info" id="dice-info">Info: </div>
          <div class="dice-total" id="dice-total">Total:</div>
        </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../assets/styles/colors';


</style>

<script setup>
import '../assets/styles/global_variables.scss'
import '../assets/styles/dice-roller-view.scss'
import RollResultsItem from '../components/roll_results_item.vue'
import DiceHistoryControl from '../components/results_history_control.vue'
import {onMounted, reactive, ref} from "vue"

const novasector_app = window.novasectorapp
const diceStore = window.novasectorapp.diceStore

let colView = null
let resContainer = null

const manual_entry = ref('')

const meta = reactive({
  num_cols: 1,
  num_rows: 4,
  row_height: 16,
  rows_per_col: 20,
  valuesMaxWidth: 0,
  sideMaxWidth: 0,
  isMultiSet: false,
  MultiSetOffset: 0,
  HistoryIndex: 0,
  cur_rec: 0,
  tot_rec: 0,
})

// const dice_history = reactive([])
const dice_results = reactive({
  dstr: 'D6',
  total: 0,
  list: [],
  // left, mid, right values of parsed dice string
  l: 1, m: 1, r: 6
})

const manual_entry_entered = ref(false)

let btn_labels = reactive([])

onMounted(() => {

  diceStore.getButtonLabels()
      .then((labels) => {
        for(const lbl of labels)
          btn_labels.push(lbl)
      })


  resContainer = document.getElementById('res-container')
  colView = document.querySelector('#results-cols')
  const viewElem = document.getElementById('dice_results_view')

  diceStore.createTmpElement()
  document.body.append(diceStore.tmpElement)

  const viewHeight = viewElem.clientHeight
  const elmHeight = diceStore.tmpElement.clientHeight
  const maxRows = viewHeight / (elmHeight+4)

  meta.num_rows = dice_results.list.length
  meta.row_height = elmHeight + 10
  meta.rows_per_col = dice_results.list.length / maxRows

  // restoring dice history when mounted
  restore_dice_history()
})

function restore_dice_history() {
  if(diceStore.history === undefined || diceStore.history.length === 0) {
    diceStore.history = []
    return
  }

  if(diceStore.history.length !== 0) {
    const dh = diceStore.history[diceStore.cur_rec-1]
    Object.assign(dice_results, dh)
    // for(const k in dh)
    //   dice_results[k] = dh[k]
    diceStore.pull_from_history()
    
    if(dice_results.m > 1)
      meta.MultiSetOffset = dice_results.m - 1
  }
  updateView()

}
function prep_view() {
  const cSet = diceStore.currentSet
  Object.assign(dice_results, cSet)

  meta.sideMaxWidth = cSet.maxValWidth
  meta.valuesMaxWidth = cSet.maxCntWidth

  meta.num_rows = cSet.list.length
  meta.tot_rec = diceStore.history.length
  meta.cur_rec = diceStore.cur_rec // offset in to dice roll history; 0 == current record.
}

function dice_roll(dstr) {
  diceStore.roll(dstr)
      .then((res) => {
        // res['dstr'] = dstr

        // turn off the result-cols. Will be adjusting the widths of the rows in
        // the columns, and this is slow and causing the change to be seen. I
        // want to avoid this. turn off now, turn back on after changing widths.
        colView.style.display = 'none'

        // Object.assign(dice_results, res)
        meta.isMultiSet = false
        meta.MultiSetOffset = 0
        if(diceStore.currentSet.m > 1) {
          meta.isMultiSet = true
          meta.MultiSetOffset = diceStore.currentSet.m - 1
        }

        updateView()

      })
}

function manual_roll(dstr) {
  manual_entry_entered.value = true
  dice_roll(dstr)
}

function update_data() {
  const cSet = diceStore.currentSet
  const totWidth = (meta.sideMaxWidth + meta.valuesMaxWidth) * 1.65

  const max_rows = Math.floor(resContainer.clientHeight / (meta.row_height+0.5) )

  let num_rows = cSet.list.length
  if(num_rows > max_rows)
    num_rows = max_rows

  const num_cols = Math.floor((cSet.list.length / num_rows) + 0.5 )

  const viewWidth = totWidth * num_cols

  colView.style.width = viewWidth + 'px'
  colView.style.maxWidth = resContainer.clientWidth + 'px'
}

function update_footer() {
  // need a bit of a delay to allow for child components to adjust
  // their size before being displayed.
  setTimeout(() => {
    colView.style.display = 'flex'
    document.getElementById('dice-info').innerText = dice_results.dstr
    document.getElementById('dice-total').innerText = 'Total: ' + dice_results.total
  }, 100)
}

function history_step(step) {
  diceStore.history_step(step)

  updateView()
}

function history_goto(goto) {
  diceStore.history_goto(goto)
  updateView()

}

function updateView() {
  prep_view()
  update_data()
  update_footer()
}

</script>
